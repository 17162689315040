<template>
  <v-data-table
    v-model="selected"
    class="tp-table-scroll datatable px-3 py-2"
    calculate-widths
    disable-pagination
    no-data-text="Không có dữ liệu"
    no-results-text="Không tìm thấy kết quả phù hợp"
    :headers="headers"
    hide-default-footer
    :loading="false"
    loading-text="Đang tải dữ liệu"
    :items="productOptions"
    item-key="id"
    @click:row="viewDetail"
  >
    <template v-slot:[`item.status`]="{ item }">
      <v-chip
        class="font-weight-bold px-2"
        :color="
          item.status === 1
            ? 'green'
            : item.status === 2
            ? 'red accent-2'
            : 'orange'
        "
        outlined
        small
        >{{
          item.status === 1 ? "Hoàn thành" : item.status === 2 ? "Uhm" : "Uhmm"
        }}
      </v-chip>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    selectedProductOptions: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      headers: [
        {
          text: "Mã PKK",
          align: "start",
          sortable: false,
          value: "code"
        },
        {
          text: "Thời gian",
          align: "start",
          sortable: false,
          value: "created_at"
        },
        {
          text: "Kho",
          align: "start",
          sortable: false,
          value: "branch"
        },
        {
          text: "Nhân viên",
          align: "start",
          sortable: false,
          value: "by"
        },
        {
          text: "Serials đã kiểm",
          align: "center",
          sortable: false,
          value: "doneCount"
        },
        {
          text: "Serials đúng",
          align: "center",
          sortable: false,
          value: "rightCount"
        },
        {
          text: "Serials sai",
          align: "center",
          sortable: false,
          value: "wrongCount"
        },
        {
          text: "Serials chưa kiểm",
          align: "center",
          sortable: false,
          value: "waitingCount"
        },
        {
          text: "Trạng thái",
          align: "center",
          sortable: false,
          value: "status"
        }
      ]
    };
  },
  computed: {
    productOptions() {
      return this.$store.getters["INVENTORY/inventories"];
    },
    productOptionStatusRequest() {
      return this.$store.getters["INVENTORY/statusRequest"];
    },
    selected: {
      get() {
        return this.selectedProductOptions;
      },
      set(val) {
        this.$emit("updateSelectedProductOptions", val);
      }
    }
  },
  filters: {
    formatCurrency(value) {
      // Create our number formatter.
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },
  methods: {
    copyToClipboard(str) {
      const el = document.createElement("textarea");

      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);

      this.$toast.show({
        name: "toast-action-alert",
        payload: {
          message: "Đã sao chép vào bộ nhớ tạm"
        }
      });
    },

    getLaunchStatus(val) {
      if (val === 1) return null;
      else if (val === 2) return "orange lighten-4";
      else return "cyan lighten-4";
    },

    async openModalProduct(product) {
      await this.$store.dispatch("PRODUCT/getProductById", product.id);
      await this.$store.dispatch(
        "PRODUCT_OPTION/getProductOptionsByProductId",
        product.id
      );

      this.$modal.show({
        name: "modal-product"
      });
    },

    async viewDetail(item) {
      this.$router.push({
        name: "goods_inventory-detail",
        params: { inventoryId: item.id }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.datatable {
  .v-data-table-header {
    background-color: green;
  }
  .copied-label {
    cursor: pointer;
    display: inline-block;
  }
  .view-label {
    cursor: pointer;
    transition: all 0.4s ease;
  }
}
</style>
