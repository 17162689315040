<template>
  <div class="action mr-5 mb-4">
    <!-- Start: Action row -->
    <div class="action-row d-flex align-center justify-space-between">
      <div class="action-row--col flex-grow-1 pr-5">
        <tp-search-option
          :options="productSearchOptions"
          :value="searchKey"
          @search="updateSearchKey($event)"
        ></tp-search-option>
      </div>

      <div class="action-row--col">
        <v-btn
          class="rounded-lg"
          color="primary"
          depressed
          @click="gotoCreateInventory()"
        >
          <v-icon left>mdi-plus</v-icon> Bắt đầu
        </v-btn>

        <v-btn
          class="ml-3 rounded-lg"
          color="primary"
          dark
          depressed
          :loading="
            inventoryStatusRequest.value === 'loading-adjustmentImportPrice'
          "
          outlined
          @click="$refs.file.click()"
        >
          <v-icon left>mdi-file-upload-outline</v-icon> Nhập file
        </v-btn>

        <input
          type="file"
          ref="file"
          hidden
          accept=".xlsx, .xls"
          @change="uploadExcelFile()"
        />
      </div>
    </div>
    <!-- End: Action row -->
    <!-- Start: Action row -->
    <div class="action-row d-flex align-center justify-space-between mt-4">
      <div class="action-row--col align-center">
        <!-- Start: Show filter dropdown -->
        <tp-btn-overflow
          btn-class="white rounded-lg"
          :items="showFilterList"
          :selected-value="selectedShowFilterId"
          @change="selectedShowFilterIndex = $event"
          @select="selectShowFilter($event)"
        ></tp-btn-overflow>
        <!-- End: Show filter dropdown -->
        <!-- Start: Action dropdown -->
        <tp-btn-overflow
          v-if="selectedProductOptions.length > 0"
          btn-class="white rounded-lg ml-3"
          :active-item="false"
          :items="actionList"
          placeholder="Thao tác"
          @select="selectAction($event)"
        ></tp-btn-overflow>
        <!-- End: Action dropdown -->
        <div class="ml-3" v-if="selectedProductOptions.length > 0">
          Đã chọn <strong>{{ this.selectedProductOptions.length }}</strong> sản
          phẩm
        </div>
      </div>
      <div class="action-row--col d-flex align-center">
        <div class="text-body-2">
          {{ paginationInfo.from }} - {{ paginationInfo.to }} trong số
          {{ paginationInfo.itemTotal }}
        </div>
        <v-pagination
          v-model="curPage"
          color="primary"
          :length="paginationInfo.pageTotal"
          :total-visible="5"
        ></v-pagination>
      </div>
    </div>
    <!-- End: Action row -->
  </div>
</template>

<script>
export default {
  props: {
    currentPage: {
      type: Number
    },
    selectedProductOptions: {
      type: Array,
      required: true
    },
    selectedShowFilterId: {
      type: Number
    },
    searchKey: {
      type: String
    }
  },

  data() {
    return {
      actionList: [
        { id: "delete", text: "Xóa" },
        { id: "group", text: "Nhóm sản phẩm" },
        { id: "hide-show", text: "Ẩn/Hiện trên website" }
      ],
      productSearchOptions: [
        { type: "search", label: "Tìm kiếm" }
        // { type: "option/search", label: "Theo mã, tên SKU" },
        // { type: "serial/search", label: "Theo serial" }
      ],
      showFilterList: [
        { id: 25, text: "Hiển thị 25" },
        { id: 50, text: "Hiển thị 50" },
        { id: 100, text: "Hiển thị 100" }
      ]
    };
  },
  computed: {
    curPage: {
      get() {
        return this.currentPage;
      },
      set(val) {
        this.$emit("updateCurrentPage", val);
      }
    },
    paginationInfo() {
      return this.$store.getters["INVENTORY/paginationInfo"];
    },
    inventoryStatusRequest() {
      return this.$store.getters["INVENTORY/statusRequest"];
    }
  },
  methods: {
    gotoCreateInventory() {
      this.$router.push({ name: "goods_inventory-create" });
    },

    selectAction(val) {
      if (val.id === "hide-show") {
        this.$modal.show({
          name: "modal-product-options-change-front-display",
          payload: {
            selectedLength: this.selectedProductOptions.length,
            selectedItems: this.selectedProductOptions
          }
        });
      }
    },

    selectShowFilter(val) {
      this.$emit("updateItemPerPage", val.id);
    },

    updateSearchKey(val) {
      this.$emit("updateSearchKey", val);
    },

    async uploadExcelFile() {
      const excelFile = this.$refs.file.files;
      const formData = new FormData();

      Array.from(excelFile).forEach(f => {
        formData.append("file", f);
      });
      // Import request
      await this.$store.dispatch("INVENTORY/adjustmentImportPrice", formData);

      // Check request status
      if (
        this.inventoryStatusRequest.value === "success-adjustmentImportPrice"
      ) {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Đã nhập file thành công"
          }
        });
      } else if (
        this.inventoryStatusRequest.value === "error-adjustmentImportPrice"
      ) {
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title: `<span class="red--text text--accent-2">${this.inventoryStatusRequest.message}</span>`,
            cancelBtnText: "OK"
          }
        });
      }
      // Reset ref
      const input = this.$refs.file;
      input.type = "text";
      input.type = "file";
    }
  }
};
</script>

<style lang="scss" scoped>
.action {
  &-row {
    &--col {
      display: flex;
    }
  }
}
</style>
